import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Link, LinkProps } from '../../link/link'
import { StyledLinkItemText, StyledListItem, StyledListItemGrid, StyledTextSpan } from './styledMobileMenuItem'
import Grid from '@material-ui/core/Grid'

export type MobileMenuUtilityItemProps = React.ComponentProps<typeof StyledListItem> & Pick<LinkProps, 'href'> & {
  icon: ReactNode
  children: string
  setClosed?: () => void
}

export const StyledIconContainer = styled.div`
  margin-right: ${props => props.theme.typography.pxToRem(10)};
  margin-bottom: ${props => props.theme.typography.pxToRem(10)};
  flex-direction: row;
  align-items: baseline;
  display: flex;
`

export const MobileMenuUtilityItem: React.FC<MobileMenuUtilityItemProps> = ({ setClosed, onClose, href, icon, children, ...props }) => {
  return (
    <Link href={href} passHref>
      <StyledListItem $isLink={true} onClick={setClosed} $utility={true} {...props}>
        <StyledListItemGrid container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Grid item>
            <StyledTextSpan>
              <StyledIconContainer>
                { icon }
              </StyledIconContainer>
              <StyledLinkItemText $isUtility>
                { children }
              </StyledLinkItemText>
            </StyledTextSpan>
          </Grid>
        </StyledListItemGrid>
      </StyledListItem>
    </Link>
  )
}
